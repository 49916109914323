import React from 'react';

const DownloadList = () => {
  return (
    <div>
        Download
    </div>
  );
};

export default DownloadList;
